import * as _client2 from "@redis/client";
var _client = "default" in _client2 ? _client2.default : _client2;
import * as _bloom2 from "@redis/bloom";
var _bloom = "default" in _bloom2 ? _bloom2.default : _bloom2;
import * as _graph2 from "@redis/graph";
var _graph = "default" in _graph2 ? _graph2.default : _graph2;
import * as _json2 from "@redis/json";
var _json = "default" in _json2 ? _json2.default : _json2;
import * as _search2 from "@redis/search";
var _search = "default" in _search2 ? _search2.default : _search2;
import * as _timeSeries2 from "@redis/time-series";
var _timeSeries = "default" in _timeSeries2 ? _timeSeries2.default : _timeSeries2;
var exports = {};
var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __exportStar = exports && exports.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createCluster = exports.createClient = void 0;
const client_1 = _client;
const bloom_1 = _bloom;
const graph_1 = _graph;
const json_1 = _json;
const search_1 = _search;
const time_series_1 = _timeSeries;
__exportStar(_client, exports);
__exportStar(_bloom, exports);
__exportStar(_graph, exports);
__exportStar(_json, exports);
__exportStar(_search, exports);
__exportStar(_timeSeries, exports);
const modules = {
  ...bloom_1.default,
  graph: graph_1.default,
  json: json_1.default,
  ft: search_1.default,
  ts: time_series_1.default
};
function createClient(options) {
  return (0, client_1.createClient)({
    ...options,
    modules: {
      ...modules,
      ...options?.modules
    }
  });
}
exports.createClient = createClient;
function createCluster(options) {
  return (0, client_1.createCluster)({
    ...options,
    modules: {
      ...modules,
      ...options?.modules
    }
  });
}
exports.createCluster = createCluster;
export default exports;
export const __esModule = exports.__esModule;
const _createCluster = exports.createCluster,
  _createClient = exports.createClient;
export { _createCluster as createCluster, _createClient as createClient };